@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  width: 100%;
  header {
    height: 20vh;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    position: relative;
    &::after {
      top: 0px;
      position: fixed;
      content: '';
      width: 120%;
      left: -10%;
      top: -10vh;
      height: 30vh;
      background-color: $purple;
      border-radius: 100%;
      @media only screen and (min-width: $md-screen) {
        display: none;
      }
    }
    @media only screen and (min-width: $md-screen) {
      border-bottom-left-radius: 22px;
      border-bottom-right-radius: 22px;
      background-color: $purple;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
    .icon {
      z-index: 1;
      width: 86px;
      height: 86px;
      background-color: $secondary-color;
      transform: translateY(62px);
      border-radius: 43px;
      border: 4px solid $white;
      @include flex-center;
      box-shadow: 0px 3.773px 22.638px rgba(178, 178, 178, 0.20);
      p {
        @include poppinsMedium;
        font-size: 38px;
        color: $white;
        margin: 0px;

      }
    }
    @media only screen and (min-width: $md-screen) {
      .content {
        width: 100%;
        max-width: 1100px;
        padding-left: 20px;
        flex-direction: row;
        margin: 0px auto;
        justify-content: flex-start;
      }
    }
  }
  main {
    height: calc(80vh - 50px);
    overflow-y: auto;
    @include scrollbar;
    .content {
      position: relative;
      width: 100%;
      max-width: 1100px;
      margin: 0px auto;
      @media only screen and (min-width: $md-screen) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80%;
      }
      .col {
        margin-top: 40px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: $md-screen) {
          align-items: flex-start;
          padding: 40px;
          width: 45%;
        }
        @media only screen and (min-width: 1140px) {
          padding: 40px 20px;
        }
        .field {
          width: 100%;
          margin-bottom: 15px;
          max-width: 350px;
          @media only screen and (min-width: 1140px) {
            max-width: 100%;
          }
        }
        .submit {
          margin-top: 20px;
          @include btn;
          @include poppinsBold;
          text-decoration: none;
          width: 120px;
          background-color: $purple;
          border: 1px solid $purple;
        }
      }
      img {
        position: absolute;
        right: 30px;
        top: 50px;
        height: 100%;
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: block;
        }
      }
    }
  }
}