@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  width: 100%;
  &.disabled {
    pointer-events: none;
  }
  .list {
    padding: 10px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: center;
      padding: 40px 0px;
      width: 100%;
    }
    .option {
      border-radius: 8px;
      border: #EAE8E3;
      background: #FFF;
      box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.40);
      margin-bottom: 10px;
      padding: 10px 8px;
      transform: none!important;
      p {
        margin: 0px 0px 10px 0px;
      }
      @media only screen and (min-width: $md-screen) {
        width: 160px;
        margin: 0px 20px;
        display: flex; 
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        p {
          text-align: center;
          @include poppinsMedium;
        }
      }
      @media only screen and (min-width: 1000px) {
        width: 200px;
      }
      @media only screen and (min-width: 1200px) {
        width: 250px;
      }
      &.text {
        height: 100px;
        p {
          max-width: 80%;
          text-align: center;
          margin: 0px;
        }
        @media only screen and (min-width: $md-screen) {
          flex-direction: column;
          align-items: center;
        }
      }
      &.empty {
        background: #EAE8E3;
        min-height: 39px;
        pointer-events: none;
        box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.01);
        @media only screen and (min-width: $md-screen) {
          min-height: 95px;
        }
        &.pictures {
          height: 120px;
          &.text {
            height: 151px;
          }
        }
      }
      &.response {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 0px;
        &.hover {
          background-color: rgba($purple, 0.2);
        }
        p {
          width: 100%;
          padding: 10px 20px 0px 20px;
        }

        @media only screen and (min-width: $md-screen) {
          &.QRA_C_CHARACTERS {
            .input {
              min-height: 100px;
            }
          }
          &.QRA_R_TAXONOMY {
            .input {
              min-height: 100px;
            }
          }
        }

        .input {
          width: calc(100% - 20px);
          border-radius: 5px;
          margin: 10px;
          background: #EAE8E3;
          min-height: 40px;
          @include flex-center;
          @include transition;
          p {
            padding: 5px 20px;
          }
          &.hover {
            background-color: rgba($purple, 0.5);
          }
          &.active {
            background-color: $purple;
            text-align: center;
            cursor: pointer;
            p {
              color: $white;
              margin: 0px;
            };
          }
          &.match {
            justify-content: space-between;
          }
          &.pictures-active {
            background-color: transparent;
            cursor: pointer;
            p {
              color: $black;
            };
          }
        }
        @media only screen and (min-width: $md-screen) {
          p {
            width: 100%;
            padding: 10px 20px 0px 20px;
          }
          .input {
            width: calc(100% - 20px);
            min-height: 70px;
            p {
              padding: 5px 20px;
            }
          }
        }
      } 
    }
  }
}

.option-pictures {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  &.matchPictures {
    width: calc(50% - 5px);
  }
  &.row-1 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    img {
      height: calc(50% - 5px);
      height: 100px;
      border-radius: 10px;
      width: calc(100%);
      @media only screen and (min-width: $md-screen) {
        height: 100px;
      }
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  &.row-2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    img {
      height: 100px;
      border-radius: 10px;
      width: calc(50% - 5px);
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  &.row-3 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 85px;
    @media only screen and (min-width: $md-screen) {
      height: 60px;
    }
    @media only screen and (min-width: $xl-screen) {
      height: 85px;
      display: flex;
    }
    img {
      height: 100%;
      border-radius: 10px;
      width: calc(33% - 5px);
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}