@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  &.disabled {
    pointer-events: none;
  }
  @media only screen and (min-width: $md-screen) {
    align-items: center;
    &.withPictures {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      p {
        padding: 0px;
      }
      .picture-option {
        &.row-2 {
          width: calc(50% - 10px);
          margin-bottom: 0px;
          &:not(.withText) {
            height: 200px;
          }
        }
        &.row-3 {
          width: calc(33% - 10px);
          margin-top: 10px;
          margin-bottom: 0px;
          &:not(.withText) {
            height: auto;
          }
        }
      }
    }
  }
  @media only screen and (min-width: $lg-screen) {
    &.withPictures {
      width: 100%;
    }
  }
  .picture-option {
    cursor: pointer;
    width: 100%;
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    @include transition;
    &.active {
      outline: 3px solid $purple;
      background-color: rgba($purple, .2);
    }
    p {
      padding: 10px 5px 0px 10px;
      line-height: 18px;
      margin: 0px;
      text-align: left;
      font-size: 16px;
    }
    &.not-active {
      opacity: 0.6;
    }
    .option-pictures {
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
      &.row-1 {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 10px;
        img {
          height: 160px;
          border-radius: 10px;
          width: calc(100%);
          @media only screen and (min-width: $md-screen) {
            &:not(:last-child) {
              margin-bottom: 0px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 0px;
          }
        }
      }
      &.row-2 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px;
        img {
          height: 160px;
          border-radius: 10px;
          width: calc(50% - 5px);
          &:not(:last-child) {
            margin-bottom: 0px;
          }
        }
        @media only screen and (min-width: $md-screen) {
          flex-direction: column;
          img {
            height: 100px;
            width: calc(100%);
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
      &.row-3 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px;
        height: 160px;
        img {
          height: 100%;
          border-radius: 10px;
          width: calc(33% - 5px);
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        @media only screen and (min-width: $md-screen) {
          display: flex;
          flex-direction: column;
          height: 100%;
          img {
            width: 100%;
            height: 80px;
          }
        }
        @media only screen and (min-height: 800px) {
          img {
            height: 100px;
          }
        }
      }
    }
    &.row-1 {
      width: 100%;
      height: 50%;
    }
    &.row-2 {
      width: 100%;
      margin-bottom: 20px;
      @media only screen and (min-width: $md-screen) {
        height: auto;
      }
      &.withText {
        height: auto;
      }
    }
    &.row-3 {
      width: 100%;
      margin-bottom: 20px;
      @media only screen and (min-width: $md-screen) {
        width: calc(33.33% - 10px);
      }
      @media only screen and (min-width: $md-screen) {
        &.withText {
          .option-pictures {
            img {
              height: 200px;
            }
          }
        }
      }
    }
  }
  .text-option {
    cursor: pointer;
    margin-top: 10px;
    background-color: transparent;
    width: 100%;
    border: 1px solid #d3d2ce;
    padding: 15px 20px;
    border-radius: 10px;
    @media only screen and (min-width: $md-screen) {
      max-width: 400px;
    }
    p {
      margin: 0px;
      padding: 0px;
      text-align: left;
      padding: 0px;
      @include regular;
      font-size: 14px;
      @media only screen and (min-width: $md-screen) {
        text-align: center;
      }
    }
    &.active {
      background-color: $purple;
      border: 1px solid $purple;
      p {
        color: $white;
      }
    }
  }
}