@mixin regular {
  font-family: "NunitoSans";
}

@mixin semiBold {
  font-family: "NunitoSans";
}

@mixin bold {
  font-family: "NunitoSans-Bold";
}

@mixin poppinsBold {
  font-family: "Poppins-SemiBold";
}

@mixin poppinsMedium {
  font-family: "Poppins-Medium";
}

@mixin poppinsRegular {
  font-family: "Poppins-Regular";
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($purple, 0.2);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin label {
  @include regular;
  font-size: 13px;
  line-height: 13px; 
  color: $text-color;
  white-space: nowrap;
  display: block;
  margin-left: 20px;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}


@mixin btn {
  @include semiBold;
  @include transition;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  padding: 11px 20px;
  color: $white;
  border: solid 1px $primary-color;
  border-radius: 50px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin container {
  width: 100%;
  margin-top: 76px;
  height: calc(100vh - 152px);
  @media only screen and (min-width: $lg-screen) {
    margin-top: 0px;
    height: calc(100vh - 76px);
  }
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin containerField {
  margin-bottom: 20px;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin content {
  height: calc(100vh - 125px);
  width: 100%;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: $xl-screen) {
    background-color: #F6F7F8;
    height: calc(100vh - 70px);
    margin-top: 70px;
  }
}

@mixin authTemplate {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $purple;
  @media only screen and (min-width: $lg-screen) {
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    background-color: $purple;
  }
  .picture {
    background-repeat: no-repeat;
    height: 35vh;
    width: 100%;
    @include flex-center();
    align-items: flex-end;
    @media only screen and (min-height: 750px) {
      height: 50vh;
    }
    @media only screen and (min-width: $lg-screen) {
      width: calc(50%);
      height: 100%;
      margin-left: 0px;
      margin-top: 0px;
      align-items: center;
    }
    .mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      overflow: hidden;
      @media only screen and (min-width: $lg-screen) {
        display: none;
      }
      img {
        float: right;
        height: 20vh;
        transform: translate(40px, 32px);
        @media only screen and (min-height: 750px) {
          height: 28vh;
        }
      }
      p, h2 {
        @include poppinsRegular;
        margin-left: 30px;
        padding-bottom: 10px;
        width: calc(100% - 30px);
        @media only screen and (min-width: $xs-screen) {
          margin-left: calc(((100% - 480px) / 2));
          padding-bottom: 10px;
          width: calc(100% - ((100% - 400px)) / 2);
        }
      }
      p {
        color: rgba($white,0.75);
        border-bottom: 2px solid rgba($white,0.65);
      }
      h2 {
        @include poppinsBold;
        color: $white;
        font-size: 22px;
        line-height: 24px;
        @media only screen and (min-width: $xs-screen) {
          font-size: 32px;
          line-height: 34px;
        }
      }
    }
    .desktop {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        @include flex-center;
        img {
          width: 90px;
          margin: 30px auto;
        }
      }
      img {
        width: 70%;
      }
      @media only screen and (min-width: $lg-screen) {
        @include flex-center;
        flex-direction: column;
      }
    }
  }
  .content {
    width: 100%;
    height: 65vh;
    z-index: 1;
    overflow: auto;
    background-color: $white;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    display: flex;
    justify-content: center;
    @media only screen and (min-height: 750px) {
      height: 50vh;
    }
    @media only screen and (min-width: $lg-screen) {
      align-items: center;
      width: 50%;
      height: auto;
      background-color: $white;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 0px;
      height: 100%;
    } 
    .form {
      width: 100%;
      max-width: 450px;
      padding: $margin-lg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @media only screen and (min-width: $lg-screen) {
        max-width: 500px;
      }
      h3 {
        display: none;
        text-align: left;
        @include poppinsBold;
        width: 100%;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 30px;
        @media only screen and (min-width: $lg-screen) {
          display: block;
        }
      }
      button {
        @include btn;
        @include poppinsBold;
        background-color: $purple;
        border: 1px solid $purple;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
  }
}
