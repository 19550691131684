@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.container {
  width: 100%;
  .loader {
    width: 100%;
    height: 100%;
    @include flex-center;
  }
  .back {
    font-size: 30px;
    position: absolute;
    left: 10px;
    z-index: 20;
    top: 10px;
    @media only screen and (min-width: $md-screen) {
      top: 20px;
      left: 115px;
      color: $white;
      font-size: 26px;
    }
  }
  @media only screen and (min-width: $md-screen) {
    background-image: url('../../../assets/images/fond.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $purple;
  }
  header {
    position: absolute;
    z-index: 2;
    left: 0px;
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      width: calc(100vw - 300px);
      left: 150px;
    }
    .infos {
      margin-top: 30px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (min-width: $md-screen) {
        margin-top: 20px;
      }
      .name {
        h2 {
          display: none;
          margin: 0px;
          font-size: 18px;
          @include poppinsBold;
          color: $white;
          @media only screen and (min-width: $md-screen) {
            display: block;
          }
        }
      }
      .count {
        position: absolute;
        right: 15px;
        display: flex;
        @include poppinsMedium;
        justify-content: flex-end;
        align-items: center;
        @media only screen and (min-width: $md-screen) {
          right: 0px;
        }
        p {
          color: rgba($black, 0.5);
          @include poppinsMedium;
          font-size: 18px;
          margin: 0px 15px;
          @media only screen and (min-width: $md-screen) {
            color: $white;
          }
        }
      }
    }
    .progress {
      margin-top: 10px;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: rgba($white, 0.2);
      display: none;
      @media only screen and (min-width: $md-screen) {
        display: block;
      }
      .bar {
        background-color: $white;
        @include transition;
        border-radius: 2px;
        height: 4px;
      }
    }
  }
  .content {
    width: 100%;
    overflow: hidden;
    height: 100%;
    .list {
      position: relative;
      display: flex;
      height: 100%;
      z-index: 2;
      @include transition;
      .question {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100%;
        z-index: 1;
        @include transition;
        &.prev {
          @media only screen and (min-width: $md-screen) {
            transform: translateX(250px) scale(0.9);
            opacity: 0.5;
            z-index: -1;
          }
        }
        &.next {
          @media only screen and (min-width: $md-screen) {
            transform: translateX(-250px) scale(0.9);
            opacity: 0.5;
            z-index: -1;
          }
        }
        .empty-question {
          position: relative;
          width: 100vw;
          height: 100%;
          padding: 50px 0px 20px 0px;
          overflow-y: auto;
          background-color: $white;
          @media only screen and (min-width: $md-screen) {
            width: calc(100vw - 300px);
            padding: 50px;
            margin: auto;
            height: calc(100% - 200px);
            border-radius: 20px;
          }
        }
      }
    }
  }
  .end {
    width: 100vw;
    height: 100%;
    padding: 50px 0px 20px 0px;
    overflow-y: auto;
    z-index: 200;
    @include flex-center;
    flex-direction: column;
    img {
      &.desktop {
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: block;
        }
      }
      &.mobile {
        @media only screen and (min-width: $md-screen) {
          display: none;
        }
      }
    }
    h1 {
      @include poppinsBold;
      font-size: 50px;
      margin-bottom: 0px;
      color: $purple;
    }
    p {
      @include poppinsBold;
      color: $purple;
      font-size: 24px;
      padding: 0px 20px;
      text-align: center;
    }
    @media only screen and (min-width: $md-screen) {
      br {
        display: none;
      }
      h1, p {
        color: $white;
      }
      h1 {
        font-size: 50px;
        margin-bottom: 0px;
      }
      p {
        font-size: 32px;
      }
    }
  }
}