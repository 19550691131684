@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  position: fixed;
  height: 50px;
  bottom: 0px;
  width: 100%;
  background-color: $white;
  box-shadow: 0px -2px 37px 0px rgba(0, 0, 0, 0.10);
  @media only screen and (min-width: $md-screen) {
    background-color: transparent;
    top: 0px;
    height: 70px;
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
  }
  .mobile {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      display: none;
    }
    a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 25%;
      .link {
        @include transition;
        width: 36px;
        height: 36px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon {
          width: 30px;
          @include transition;
          height: 30px;
          min-width: 30px;
          border-radius: 36px;
          @include flex-center;
          img {
            width: 100%;
            height: 100%;
          }
          .active-img {
            display: none;
          }
        }
      }
      &.active {
        .link {
          .icon {
            .active-img {
              display: block;
            }
            .img {
              display: none;
            }
          }
        } 
      }
    }
  }
  .desktop {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content:  space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0px 20px;
    }
    .logo {
      width: 230px;
      img {
        width: 80px;
        @media only screen and (min-width: 1200px) {
          margin-left: 10px;
        }
      }
    }
    .links {
      width: 280px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        @include flex-center;
        width: calc(50% - 10px);
        height: 40px;
        @include semiBold;
        text-decoration: none;
        background-color: #5e65a7;
        border-bottom:  3px solid transparent;
        @include poppinsBold;
        line-height: 14px;
        border-radius: 6px;
        &.active {
          background-color: $white;
          p {
            color: $black;
          }
          img {
            opacity: 1;
          }
        }
        img {
          transform: translateY(1px);
          opacity: 0.7;
        } 
        p {
          color: rgba($white, 0.6);
          font-size: 14px;
          line-height: 12px;
          @include poppinsBold;
          margin: 0px 4px;
          transform: translateY(1.5px);
        }
      }

    }
    .user {
      width: 260px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        p {
          margin: 0px;
        }
        .icon {
          background-color: $white;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          @include poppinsBold;
          font-size: 24px;
          @include flex-center;
          text-transform: uppercase;
          margin-right: 10px;
          p {
            font-size: 16px;
            @include poppinsBold;
            color: $purple;
          }
        }
        p {
          color: $white;
          @include poppinsRegular;
        }
      }
      button {
        cursor: pointer;
        margin-left: 10px;
        background-color: transparent;
        @include flex-center;
        border: none;
        font-size: 20px;
        color: $white;
      }
    }
  }
}