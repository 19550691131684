@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  &.disabled {
    pointer-events: none;
  }
  @media only screen and (min-width: $md-screen) {
    align-items: center;
  }
  .select {
    @media only screen and (min-width: $md-screen) {
      width: 400px;
    }
    .label {
      cursor: pointer;
      margin-top: 10px;
      width: 100%;
      max-width: 400px;
      padding: 15px 20px;
      border-radius: 10px;
      background-color: transparent;
      border: 0px solid transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0px;
        padding: 0px;
        text-align: left;
        padding: 0px;
        @include regular;
        color: $white;
        font-size: 14px;
        &:last-child {
          text-align: right;
        }
      }
    }
    .items {
      .label {
        p {
          color: $black;
          &:last-child {
            text-align: left;
          }
        }
      }
    }
  }

}