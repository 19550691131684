@import "../../styles/_variables.scss";
@import "../../styles/mixins";


.container {
  display: flex;
  align-items: center;
  position: relative;
  button {
    background-color: transparent;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    @include flex-center;
    font-size: 22px;
    padding: 0px;
    color: $black;
    cursor: pointer;
    &.reset {
      right: 5px;
      position: absolute;
      color: rgba($black, 0.6);
      margin-right: 0px;
    }
  }
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    @include transition;
    background-color: $white;
    border-radius: 20px;
    @media only screen and (min-width: $xs-screen) {
      max-width: 340px;
    }
    .icon {
      width: 30px;
      height: 30px;
      @include flex-center;
      font-size: 22px;
      margin-left: 4px;
      color: rgba($black, 0.7);
      img {
        width: 22px;
      }
    }
    input {
      @include poppinsMedium;
      padding: 12px 9px;
      background-color: transparent;
      border: none;
      font-size: 12px;
      line-height: 14px;
      color: rgba($black, 1);
      &::placeholder {
        color: rgba($black, 0.8);
      }
    }
  }
}