@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px; 
  &.home {
    label {
      @include poppinsBold;
      margin-bottom: 10px;
      font-size: 18px;
      width: 100%;
      max-width: 1100px;
      margin: 0px auto 15px auto;
    }
    .list {
      @media only screen and (min-width: 320px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (min-width: 900px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media only screen and (min-width: $lg-screen) {
        grid-template-columns: repeat(5, 1fr);
        max-width: 1100px;
        margin: auto;
      }
      .link {
        flex-direction: column;
        .picture {
          width: 100%;
          overflow: hidden;
        }
        .infos {
          padding: 10px;
          width: 100%;
        } 
      }
    }
  }
  .list {
    width: 100%;
    display: grid;
    gap: 20px;
    @media only screen and (min-width: $xs-screen) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (min-width: 700px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (min-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (min-width: $lg-screen) {
      grid-template-columns: repeat(5, 1fr);
      max-width: 1100px;
      margin: auto;
    }
    .link {
      border: none;
      padding: 0px;
      background-color: none;
      position: relative;
      text-decoration: none;
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.20);
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      @media only screen and (min-width: $xs-screen) {
        flex-direction: column;
      }
      .picture {
        width: 110px;
        height: 80px;
        min-height: 80px;
        display: flex;
        justify-content: center;
        @media only screen and (min-width: $xs-screen) {
          width: 100%;
          overflow: hidden;
        }
        @media only screen and (min-width: $lg-screen) {
          height: 90px;
          min-height: 90px;
        }
        img {
          width: 100%;
          min-height: 100%;
          object-fit: cover;
        }
      }
      .infos {
        padding-left: 20px;
        @media only screen and (min-width: $xs-screen) {
          padding: 10px;
          width: 100%;
        }
        p {
          @include poppinsBold;
          margin: 0px;
          line-height: 16px;
          &:last-child {
            font-size: 12px;
            color: $light-grey;
          }
        }
      }
      .count {
        @include poppinsBold;
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #27AC7D;
        color: $white;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        padding: 6px 10px;
        border-bottom-left-radius: 10px;
        p {
          @include poppinsBold;
          font-size: 14px;
          line-height: 14px;
          color: $white;
          margin: 0px;
        }  
        svg {
          margin-left: 2px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}