@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  position: relative;
  width: 100vw;
  max-height: calc(100vh - 100px);
  margin-top: 50px;
  padding: 0px 0px 20px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (min-width: $md-screen) {
    width: calc(100vw - 300px);
    padding: 30px 20px;
    margin: 80px auto 0px auto;
    height: calc(100% - 140px);
    border-radius: 20px;
    background-color: $white;
  }
  @media only screen and (min-width: $xl-screen) {
    padding: 0px;
  }
  .pictures {
    display: flex;
    flex-wrap: wrap;
    height: 25vh;
    margin: 10px 0px;
    &.col-1 {
      margin: 10px 20px;
      @media only screen and (min-width: $md-screen) {
        margin: 20px;
      }
      .img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &.col-2 {
      margin: 10px 20px;
      @media only screen and (min-width: $md-screen) {
        margin: 20px;
      }
      .img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &.col-3 {
      margin: 10px 20px;
      display: flex;
      flex-wrap: wrap;
      @media only screen and (min-width: $md-screen) {
        margin: 20px;
      }
      .img {
        width: 100%;
        height: calc(50% - 10px);
        border-radius: 8px;
        margin-bottom: 10px;
        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:nth-child(2) {
          margin-right: 10px;
        }
        &:nth-child(2) {
          margin-right: 10px;
        }
        &:not(:first-child) {
          width: calc(50% - 5px);
        }
      }
    }
    @media only screen and (min-width: $xl-screen) {
      position: absolute;
      margin: 0px;
      height: calc(100% - 40px);
      width: 50%;
    }
  }
  .question {
    @media only screen and (min-width: $md-screen) {
      @include flex-center;
      flex-direction: column;
      &:not(.withPictures) {
        height: 100%;
        padding: 0px;
      }
    }
    @media only screen and (min-width: $xl-screen) {
      &:not(.withPictures) {
        height: 100%;
        padding: 0px 50px;
      }
    }
    // &.disabled {
    //   pointer-events: none;   
    // }
    .sentence {
      padding: 20px 20px 10px 20px;
      margin: 0px;
      @include poppinsBold;
      @media only screen and (min-width: $md-screen) {
        text-align: center;
        padding: 0px 20px 10px 20px;
      }
    }
    @media only screen and (min-width: $md-screen) {
      .sentence {
        font-size: 18px;
      }
      &.onlyText {
        margin-top: 100px;
      }
      &.withPictures {
        .sentence {
          padding: 0px 20px 10px 20px;
        }
      }
    }
    @media only screen and (min-width: $xl-screen) {
      height: 100%;
      .sentence {
        max-width: 700px;
        padding: 20px 30px;
      }
      &.withPictures {
        position: absolute;
        margin-top: 0px;
        width: 50%;
        top: 0px;
        height: 100%;
        padding: 0px 60px 0px 40px;
        right: 0px;
        @include flex-center;
        flex-direction: column;
        .sentence {
          text-align: center;
      
        }
      }
    }
  }
}
.actions {
  position: fixed;
  height: 50px;
  bottom: 0px;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: $secondary-color;
  @include transition;
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    @include poppinsBold;
    color: $white;
    height: 100%;
    width: calc(100% - 140px);
    border: none;
    cursor: pointer;
    background-color: transparent;
    svg {
      margin-right: 8px;
      transform: translateY(-1px);
    }
    @media only screen and (min-width: $md-screen) {
      width: 250px;
      border-radius: 10px;
      box-shadow: 0px 0px 24px rgba(104, 118, 125, 0.25);
    }
  }
  &.disabled {
    background-color: #949494;
    .submit {
      pointer-events: none;
      background-color: #949494;
    }   
  }
  &.submited {
    background-color: $black;
    .submit {
      pointer-events: none;
      background-color: transparent;
      @media only screen and (min-width: $md-screen) {
        background-color: $black;
      }
    }   
  }
  @media only screen and (min-width: $md-screen) {
    position: absolute;
    width: calc(100vw - 300px);
    left: 150px;
    bottom: 35px;
    @include flex-center;
    background-color: transparent;
    .submit {
      background-color: $secondary-color;
    }
    &.disabled {
      background-color: transparent;
      .submit {
        background-color: #949494;
      }
    }
    &.submited {
      background-color: transparent;
      .submit {
        background-color: $black;
      }
    }
  }
  .nav {
    width: 50px;
    @include flex-center;
    @media only screen and (min-width: $md-screen) {
      width: 82px;
      position: fixed;
      top: calc(50vh - 38px);
      cursor: pointer;
      &:first-child {
        left: 110px;
      }
      &:last-child {
        right: 110px;
      }
    }
    button {
      @include flex-center;
      @include transition;
      padding: 0px;
      width: 36px;
      height: 36px;
      background-color: transparent;
      cursor: pointer;
      border: 1px solid $white;
      border-radius: 30px;
      color: $white;
      font-size: 20px;
      @media only screen and (min-width: $md-screen) {
        width: 82px;
        height: 82px;
        border-radius: 41px;
        background-color: $white;
        color: $purple;
        font-size: 32px;
        box-shadow: 0px 0px 24px rgba(104, 118, 125, 0.25);
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}