@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.timer {
  @include poppinsMedium;
  font-size: 18px;
  background-color: $black;
  padding: 4px 0px;
  color: $white;
  border-radius: 7px;
  width: 81px;
  text-align: center;
  .animated {
    animation: countdown 1s infinite alternate;
  }

  .minutes {
    animation-delay: 0.5s;
  }

  .seconds {
    animation-delay: 0s;
  }
}

@keyframes countdown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}