@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.container {
  width: 100%;
  header {
    height: 20vh;
    width: 100%;
    background-color: $purple;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    p {
      color: rgba($white,0.75);
      @media only screen and (min-width: $md-screen) {
        font-size: 20px;
        line-height: 20px;
        @include poppinsBold;
      }
    }
    h2 {
      @include poppinsBold;
      color: $white;
      font-size: 22px;
      line-height: 24px;
      @media only screen and (min-width: $xs-screen) {
        font-size: 32px;
        line-height: 34px;
      }
    }
    @media only screen and (min-width: $md-screen) {
      .content {
        width: 100%;
        max-width: 1100px;
        margin: 0px auto;
        br {
          display: none;
        }
      }
    }
  }
  main {
    height: calc(80vh - 50px);
    overflow-y: auto;
    @include scrollbar;
    .loader {
      height: 100%;
      @include flex-center;
    }
  }
  &.home {
    header {
      height: 220px;
    }
    main {
      height: calc(100vh - 270px);
      @media only screen and (min-width: $md-screen) {
        height: calc(100vh - 220px);
      }
    }
  }
}