@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  @include transition;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: $purple;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
  @include transition;
  pointer-events: none;
  .illu {
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(50%);
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  @media only screen and (min-width: 700px) {
    background-color: rgba($black, 0.5);
    justify-content: center;
    align-items: center;
    .illu {
      display: none;
    }
    .back {
      display: none;
    }
  }
  &.active {
    pointer-events: all;
    opacity: 1;
  }
  .back {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: transparent;
    border: none;
    font-size: 36px;
    color: $white;
  }
  .content {
    position: relative;
    z-index: 3;
    margin: 20px;
    width: calc(100vw - 40px);
    background-color: $white;
    height: calc(60vh);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 700px) {
      width: calc(80% - 40px);
      max-width: 800px;
      flex-direction: row;
      justify-content: flex-end;
    }
    .picture {
      margin: 45px 20px 0px 20px;
      overflow: hidden;
      height: 20vh;
      width: calc(100% - 40px);
      border-radius: 10px;
      &.mobile {
        @media only screen and (min-width: 700px) {
          display: none;
        }
      }
      &.desktop {
        display: none;
        @media only screen and (min-width: 700px) {
          display: block;
          width: 50%;
          height: calc(100% - 40px);
          margin: 20px;
        }
      }
      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .col {
      position: relative;
      height: 100%;
      @media only screen and (min-width: 700px) {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .infos {
        padding: 0px 20px;
        width: 100%;
        max-height: calc(40vh - 120px);
        overflow-y: auto;
        @media only screen and (min-width: 700px) {
          max-height: calc(60vh - 120px);
        }
        h2 {
          margin: 10px 0px;
          @include poppinsBold;
        }
        label {
          @include poppinsMedium;
          display: block;
          font-size: 12px;
          line-height: 16px;
          color: $light-grey;
          margin-bottom: 4px;
        }
        p {
          @include poppinsMedium;
          margin: 0px;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .plants {
        position: absolute;
        left: 20px;
        top: 10px;
        p {
          @include poppinsMedium;
          font-size: 14px;
          color: $light-grey;
        }
        @media only screen and (min-width: 700px) {
          top: 20px;
        }
      }
      .count {
        @include poppinsBold;
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #27AC7D;
        color: $white;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        padding: 10px 14px;
        border-bottom-left-radius: 10px;
        @media only screen and (min-width: 700px) {
          border-radius: 10px;
          right: 15px;
          top: 15px;
        }
        p {
          @include poppinsBold;
          font-size: 14px;
          line-height: 14px;
          color: $white;
          margin: 0px;
        }  
        svg {
          margin-left: 4px;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .start {
        position: absolute;
        width: 100%;
        bottom: 20px;
        @include flex-center;
        @media only screen and (min-width: 700px) {
          position: relative;
          bottom: 0px;
          margin-top: 20px;
        }
        a {
          @include btn;
          @include poppinsBold;
          text-decoration: none;
          width: calc(100% - 40px);
          background-color: $purple;
          border: 1px solid $purple;
        }
      }
    }
  }
}