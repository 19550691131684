@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  &.disabled {
    pointer-events: none;
  }
  @media only screen and (min-width: $md-screen) {
    align-items: center;
    width: 400px;
  }
  .field {
    margin-bottom: 15px;
    width: 100%;
  }

}